import React from "react";
import Footer from "@components/footers/dark";
import Layout from "@components/layout";
import SEO from "@components/seo";
import ReCAPTCHA from 'react-google-recaptcha';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import MenuBar from "@components/menuBar/MenuBar";
import "./balance-check.css"

class BalanceCheckPage extends React.Component {
  constructor(props) {
    super(props)

    this.recaptchaRef = React.createRef();
    this.onCaptchaChange = this.onCaptchaChange.bind(this);

    this.state = {
      number: '',
      balance: null,
      transactions: [],
      expiry: null,
      status: null,
      error: '',
      captchaResponse: null,
      isProcessing: false
    }
  }

  onSubmit = async event => {
    event.preventDefault()

    if (this.state.captchaResponse) {
      this.setState({error: null});
    } else {
      this.setState({error: 'Captcha is required'});
      return;
    }

    if (![11,19].includes(this.state.number.length)) {
      this.setState({error: 'Card number must be 11 or 19 digits', balance: null, transactions: []})
    } else {
      this.setState({isProcessing: true, error: ''})
      fetch('/.netlify/functions/balance-check', {
        method: 'POST',
        body: JSON.stringify({number: this.state.number, captchaResponse: this.state.captchaResponse})
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.balance) {
            this.setState({
              error: null,
              balance: response.balance,
              transactions: response.transactions || [],
              expiry: response.expiry,
              status: response.card_status,
              isProcessing: false,
              captchaResponse: null
            })
          } else {
            this.setState({
              error: response.message,
              balance: null,
              transactions: [],
              expiry: null,
              status: null,
              isProcessing: false,
              captchaResponse: null
            })
          }
          this.recaptchaRef.current.reset();
        })
        .catch((err) => {
          this.setState({
            error: 'Unknown error occurred',
            balance: null,
            transactions: [],
            expiry: null,
            status: null,
            isProcessing: false,
            captchaResponse: null
          })
          this.recaptchaRef.current.reset();
        });
    }
  }

  onCaptchaChange(captchaResponse) {
    this.setState({captchaResponse: captchaResponse, error: null});
  }

  render() {
    const { balance, transactions, expiry, status, error, isProcessing } = this.state

    return (
      <>
        <SEO title="Giftcards Balance Check" />
        <MenuBar/>
        <Layout>
          <section className="min-h-screen bg-gray-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
              <h1 className="text-center text-3xl font-extrabold text-gray-900">
                Balance Check
              </h1>
              <div className="sm:mx-auto bg-white shadow sm:round-lg sm:w-full sm:max-w-3xl">
                <div className="py-8 px-4 sm:px-10">
                  <form className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Card Number
                      </label>
                      <div className="mt-1">
                        <input id="email" name="card_number" type="text" autoComplete="text" maxLength="19" minLength="1" required placeholder="11 or 19 digits" onChange={(e) => this.setState({number: e.target.value})} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight focus:border-highlight sm:text-sm" />
                      </div>
                    </div>
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      sitekey={process.env.GATSBY_RECAPTCHA_KEY || ''}
                      onChange={this.onCaptchaChange}
                      className="g-recaptcha"
                      style={{marginTop: '1em'}}
                    />
                    {error && <AlertBox message={error} />}
                    <div>
                      <button type="submit" onClick={this.onSubmit} disabled={isProcessing} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-highlight hover:bg-highlight-dark disabled:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight disabled:ring-gray-300">
                        {
                          isProcessing ?
                            <Spinner/>
                            :
                            <span>Check Card Balance</span>
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {!isProcessing && balance && <Stats balance={balance} expiry={expiry} status={status} />}
              {!isProcessing && transactions.length > 0 && <Table transactions={transactions} />}
              <ContactSheet/>
            </div>
          </section>
        </Layout>
        <Footer/>
      </>
    );
  }
}

const ContactSheet = () => (
  <div className="mx-auto sm:mx-auto bg-white shadow sm:round-lg sm:w-full sm:max-w-3xl py-8 px-4 sm:px-6 lg:px-8">
      <h2 className="font-bold text-gray-900 text-xl py-4">Need help?</h2>
      <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
        <div className="flex">
          <div className="flex-shrink-0">
            <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3 text-base text-gray-500">
            <p><a href="tel:1300764721">1300&nbsp;764&nbsp;721</a></p>
            <p className="mt-1">Mon&ndash;Sun&nbsp;9am&nbsp;to&nbsp;6pm&nbsp;AEST</p>
          </div>
        </div>
        <div className="flex mt-4 md:mt-0">
          <div className="flex-shrink-0">
            <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3 text-base text-gray-500">
            <p><a href="mailto:support@waivpay.com">support@waivpay.com</a></p>
            <p className="mt-1">Mon&ndash;Sun&nbsp;9am&nbsp;to&nbsp;10pm&nbsp;AEST</p>
          </div>
        </div>
      </div>
  </div>
);

const Table = ({ transactions }) => {
  return (
    <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-3xl">
      {/* <div className="py-2 align-middle inline-block"> */}
      <div className="sm:shadow overflow-hidden sm:border-b sm:border-gray-200 sm:rounded-lg">
        <table id="transaction-history" className="min-w-full max-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs text-right font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {
              transactions.reverse().map((txn, i) =>
                <tr key={i} className="bg-white">
                  <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    <time dateTime="2020-09-20">{txn.date}</time>
                  </td>
                  <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {txn.type}
                  </td>
                  <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {txn.description}
                  </td>
                  <td className="px-2 sm:px-6 py-4 whitespace-nowrap sm:text-right text-sm text-gray-500">
                    <CurrencyFormat amount={txn.amount} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
      {/* </div> */}
    </div>
  );
};

const Stats = ({ balance, status, expiry }) => (
  <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-3xl">
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt className="text-sm font-medium text-gray-500 truncate">
          Status
        </dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">
          <Status status={status} balance={balance} />
        </dd>
      </div>

      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt className="text-sm font-medium text-gray-500 truncate">
          Expiry
        </dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">
          {expiry}
        </dd>
      </div>

      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt className="text-sm font-medium text-gray-500 truncate">
          Balance
        </dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">
          {balance}
        </dd>
      </div>
    </dl>
  </div>
)

const Status = ({ status, balance }) => {
  const value = parseInt(balance.replace(/[^0-9]/, ''));
  return value > 0 ? status : "closed";
}

const Spinner = () => (
  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

const CurrencyFormat = ({ amount }) => {
  var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return formatter.format(amount);
};

const AlertBox = ({ message }) => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
        </svg>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm text-blue-700">
          {message}
        </p>
      </div>
    </div>
  </div>
);

export default BalanceCheckPage;
